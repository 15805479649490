.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .video-container {
    height: 300px;
    width: 500px;
    text-align: center;

    .done-button {
      margin-bottom: 24px;
    }
  }

  .link-input-container {
    width: 100%;
    flex: 0 0 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .link-input {
      width: 500px;
    }
  }

  .link-output-container {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .copy-controls-container {
    width: 500px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copy-button {
    position: relative;

    &::after {
      content: 'Click To Copy Link';
      position: absolute;
      top: 2px;
      background-color: black;
      color: white;
      width: 110px;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
      opacity: 0;
      transition: 0.7s opacity;
      margin-left: 10px;
    }

    &.copy-success::after {
      content: 'Copied...';
      width: 70px;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
}
