.home {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.home .video-container {
  text-align: center;
  width: 500px;
  height: 300px;
}

.home .video-container .done-button {
  margin-bottom: 24px;
}

.home .link-input-container {
  flex: 0 0 100px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.home .link-input-container .link-input {
  width: 500px;
}

.home .link-output-container {
  flex-direction: column;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home .copy-controls-container {
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 40px;
  display: flex;
}

.home .copy-button {
  position: relative;
}

.home .copy-button:after {
  content: "Click To Copy Link";
  color: #fff;
  opacity: 0;
  background-color: #000;
  border-radius: 5px;
  width: 110px;
  margin-left: 10px;
  padding: 5px;
  font-size: 12px;
  transition: opacity .7s;
  position: absolute;
  top: 2px;
}

.home .copy-button.copy-success:after {
  content: "Copied...";
  width: 70px;
}

.home .copy-button:hover:after {
  opacity: 1;
}

html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

button {
  background-color: #0000;
  border: none;
}

/*# sourceMappingURL=index.6fd97306.css.map */
