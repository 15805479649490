// @import './components/yt-player/index.less';
// @import './components/yt-player/video-controls/index.less';
@import './pages/home/index.less';

html,
body {
  margin: 0;
  height: 100vh;
  width: 100vw;
}

button {
  background-color: transparent;
  border: none;
}
